import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Box,
  Button,
} from "@mui/material";

const TimeFilter = forwardRef((props, ref) => {
  console.log(props);
  const [filterType, setFilterType] = useState("after");
  const [selectedTime, setSelectedTime] = useState("");

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return selectedTime !== "";
    },

    doesFilterPass(params) {
      const cellValue = params.data[props.colDef.field];
      if (!selectedTime) return true;

      if (filterType === "after") {
        return cellValue >= selectedTime;
      } else {
        return cellValue <= selectedTime;
      }
    },

    getModel() {
      return this.isFilterActive()
        ? { type: filterType, value: selectedTime }
        : null;
    },

    setModel(model) {
      console.log(model);
      setFilterType(model ? model.type : "after");
      setSelectedTime(model ? model.value : "");
    },

    afterGuiAttached(params) {
      // Optional: set focus or initialize after GUI is attached
    },
  }));

  const onFilterTypeChange = (event) => {
    setFilterType(event.target.value);
    // props.onUiChange(); // Notify AG Grid of the change
    // Call filterChangedCallback after state updates
    setTimeout(() => props.filterChangedCallback(), 0);
  };

  const onTimeChange = (event) => {
    setSelectedTime(event.target.value);
    // props.onUiChange(); // Notify AG Grid of the change
    // Call filterChangedCallback after state updates
    setTimeout(() => props.filterChangedCallback(), 0);
  };
  const clearFilter = () => {
    setSelectedTime("");
    // props.onUiChange(); // Notify AG Grid of the change
    setTimeout(() => props.filterChangedCallback(), 0);
  };

  return (
    <Box
      sx={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        backgroundColor: "#121212", // Dark background color
        color: "#FFFFFF", // Light text color
      }}
    >
      <FormControl fullWidth>
        <InputLabel
          sx={{ color: "#FFFFFF" }} // Light text color for the label
        >
          Filter Type
        </InputLabel>
        <Select
          value={filterType}
          onChange={onFilterTypeChange}
          label="Filter Type"
          sx={{
            backgroundColor: "#1E1E1E", // Darker background for the select
            color: "#FFFFFF", // Light text color
          }}
        >
          <MenuItem value="after">After</MenuItem>
          <MenuItem value="before">Before</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Time"
        type="time"
        value={selectedTime}
        onChange={onTimeChange}
        InputLabelProps={{ shrink: true, sx: { color: "#FFFFFF" } }}
        inputProps={{ step: 1, ampm: true.toString() }} // 1 second step for time input
        sx={{
          backgroundColor: "#1E1E1E", // Darker background for the text field
          color: "#FFFFFF", // Light text color
          "& .MuiInputBase-input": { color: "#FFFFFF" }, // Ensure input text is light
        }}
      />
      <Button variant="contained" onClick={clearFilter}>
        Clear Filter
      </Button>
    </Box>
  );
});

export default TimeFilter;
