import {
  Container,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from "@mui/material";
import React from "react";

export default function RequestItem({
  primaryTitle,
  secondaryTitle,
  buttonText,
  requestPath,
}) {
  // const primaryTitle = "primaryTitle";
  // const secondaryTitle = "secondaryTitle";
  // const buttonText = "Download to internal backend";
  // const requestPath = "/downloadBSEFiles";
  const processRequest = async () => {
    const formatDate = (date) => {
      const day = `0${date.getDate()}`.slice(-2);
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };
    fetch(process.env.REACT_APP_INTERNALBACKEND_API_URL + requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        date: formatDate(new Date()),
      }),
    })
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
          width: "100%",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <ListItemText primary={primaryTitle} secondary={secondaryTitle} />
        </Box>
        <Button
          onClick={processRequest}
          sx={{ mt: { xs: 2, md: 0 }, ml: { md: 2 } }} // Adjust margin for spacing
        >
          {buttonText}
        </Button>
      </Box>
    </Container>
  );
}
