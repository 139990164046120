import { create } from "zustand";

const NotificationStore = create((set) => ({
  unreadCount: 0,
  notifications: [],
  incrementUnreadCount: () =>
    set((state) => ({ unreadCount: state.unreadCount + 1 })),
  resetUnreadCount: () => set({ unreadCount: 0 }),
  addNotification: (notification) =>
    set((state) => ({ notifications: [notification, ...state.notifications] })),
}));

export default NotificationStore;
