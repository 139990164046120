// src/RequireAuth.js
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

const RequireAuth = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    const checkAuth = async () => {
      if (accounts.length === 0) {
        if (inProgress === "none") {
          await instance.loginRedirect(loginRequest).catch((e) => {
            console.error("Login redirect error:", e);
          });
        }
      } else {
        const email = accounts[0].username;
        if (!email.endsWith("@alphextra.com")) {
          await instance.logoutRedirect();
        }
      }
    };

    checkAuth();
  }, [accounts, instance, inProgress]);

  return accounts.length > 0 && accounts[0].username.endsWith("@alphextra.com")
    ? children
    : null;
};

export default RequireAuth;
