import { create } from "zustand";

const useDevModeStore = create((set) => ({
  isDevMode: JSON.parse(localStorage.getItem("isDevMode")) || false,
  setIsDevMode: (value) => {
    set({ isDevMode: value });
    localStorage.setItem("isDevMode", JSON.stringify(value));
  },
}));

export default useDevModeStore;
