import { create } from "zustand";

const DetailedStrategySummaryStore = create((set) => ({
  strategyFilters: null,
  updateStrategyFilters: (newFilter) =>
    set((state) => ({
      strategyFilters: newFilter,
    })),
}));

export default DetailedStrategySummaryStore;
