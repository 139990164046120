// src/authConfig.js
export const msalConfig = {
  auth: {
    clientId: "a0cc71af-53d4-4106-832b-ce82df1546f7",
    authority:
      "https://login.microsoftonline.com/1f817d5b-9efd-4b0a-a48b-02346c80d331",
    redirectUri:
      process.env.REACT_APP_PROD === "prod"
        ? "https://dashboard.alphextra.com"
        : "https://a95f-20-235-247-130.ngrok-free.app", // Make sure this matches the Azure AD app redirect URI
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Enable cookies to store authentication state
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
