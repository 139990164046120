import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { usePlotterFilterStore } from "../../Store/PlotterFilterStore";
import Tracer from "./Tracer";
import "./PlotContainer.css";

const PlotContainer = ({ quadrant }) => {
  const [plotData, setPlotData] = useState(null);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState([]); // Change to array of objects
  const [dataPoints, setDataPoints] = useState(""); // State for X data points
  const [isMaximized, setIsMaximized] = useState(false); // State to track maximization
  const [isFetching, setFetching] = useState(false);

  const filters = usePlotterFilterStore(
    (state) => state.filters[quadrant] || {}
  );
  const setFilter = usePlotterFilterStore((state) => state.setFilter);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(
          `https://backend.alphextra.com/getPlotterOptions`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch options");
        }
        const data = await response.json();
        const formattedOptions = data.map((option) => ({
          broker: option.broker,
          id: option.id,
          bucket: option.bucket,
        }));
        setOptions(formattedOptions);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    if (filters.broker && filters.id && filters.bucket) {
      const fetchData = async () => {
        if (isFetching) return null;
        const primaryKey = `${filters.broker}@${filters.id}@${filters.bucket}`;
        try {
          setFetching(true);
          const response = await fetch(
            `https://backend.alphextra.com/getPlotterData?primaryKey=${primaryKey}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setPlotData(data.data);
          setFetching(false);
        } catch (err) {
          setError(err.message);
        }
      };

      // Fetch data initially and every 3 seconds
      fetchData();
      const intervalId = setInterval(fetchData, 1000);

      return () => clearInterval(intervalId);
    } else {
      setPlotData(null); // Clear plot data if filters are incomplete
    }
  }, [filters]);

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!options.length) {
    return <div>Loading...</div>;
  }

  const handleDataPointsChange = (event) => {
    setDataPoints(event.target.value);
  };

  const handleDoubleClick = () => {
    setIsMaximized(!isMaximized);
  };

  return (
    <div
      className={`plot-container quadrant-${quadrant} ${
        isMaximized ? "maximized" : ""
      }`}
      onDoubleClick={handleDoubleClick}
    >
      <div className="filter-bar">
        <FormControl variant="outlined">
          <InputLabel>Broker</InputLabel>
          <Select
            value={filters.broker || ""}
            onChange={(e) => setFilter(quadrant, "broker", e.target.value)}
            label="Broker"
          >
            {options
              .map((option) => option.broker)
              .filter((value, index, self) => self.indexOf(value) === index) // Unique brokers
              .map((brokerOption, index) => (
                <MenuItem key={index} value={brokerOption}>
                  {brokerOption}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined">
          <InputLabel>ID</InputLabel>
          <Select
            value={filters.id || ""}
            onChange={(e) => setFilter(quadrant, "id", e.target.value)}
            label="ID"
          >
            {options
              .filter((option) => option.broker === filters.broker) // Filter by selected broker
              .map((option) => option.id)
              .filter((value, index, self) => self.indexOf(value) === index) // Unique IDs
              .map((idOption, index) => (
                <MenuItem key={index} value={idOption}>
                  {idOption}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined">
          <InputLabel>Bucket</InputLabel>
          <Select
            value={filters.bucket || ""}
            onChange={(e) => setFilter(quadrant, "bucket", e.target.value)}
            label="Bucket"
          >
            {options
              .filter(
                (option) =>
                  option.broker === filters.broker && option.id === filters.id
              ) // Filter by selected broker and ID
              .map((option) => option.bucket)
              .filter((value, index, self) => self.indexOf(value) === index) // Unique buckets
              .map((bucketOption, index) => (
                <MenuItem key={index} value={bucketOption}>
                  {bucketOption}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          label="Last X Data Points"
          variant="outlined"
          value={dataPoints}
          onChange={handleDataPointsChange}
          type="number"
          inputProps={{ min: "1" }} // Ensure only positive numbers are allowed
        />
      </div>

      <div className="tracer-wrapper">
        {plotData ? (
          <Tracer data={plotData} quadrant={quadrant} dataPoints={dataPoints} />
        ) : (
          <div>Please select all filters to view the plot.</div>
        )}
      </div>
    </div>
  );
};

export default PlotContainer;
