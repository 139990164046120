/* eslint-disable */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional Theme applied to the grid
import DetailedStrategySummaryStore from "../../Store/DetailedStrategySummaryStore";

export default function DetailedStrategyView({ isMobile }) {
  const dashboardCellFontSize = isMobile ? 5 : 9;
  const [isFetching, setIsFetching] = useState(false); // Track if fetching is in progress

  const redColor = "indianred";
  const greenColor = "lightgreen";
  const defaultCellStyle = {
    padding: "0px",
    fontSize: dashboardCellFontSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const isTimeWithin = (timeString, duration) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const now = new Date();
    const offsetIST = 0; //5.5 * 60; // IST is UTC+5:30
    const nowInIST = new Date(now.getTime() + offsetIST * 60 * 1000);

    const providedTimeInIST = new Date(nowInIST);
    providedTimeInIST.setHours(hours);
    providedTimeInIST.setMinutes(minutes);
    providedTimeInIST.setSeconds(seconds);
    const differenceInMillis = Math.abs(nowInIST - providedTimeInIST);
    const differenceInSeconds = differenceInMillis / 1000;
    const isWithinXSeconds = differenceInSeconds <= duration;
    return isWithinXSeconds;
  };
  const lastUpdatedTimeLagColorLogic = (params) => {
    if (!isTimeWithin(params.value, 30)) {
      return {
        ...defaultCellStyle,
        background: redColor,
      };
    } else return defaultCellStyle;
  };
  const redGreenCellColorLogic = (params) => {
    if (params.value < 0) {
      return {
        ...defaultCellStyle,
        background: redColor,
      };
    } else if (params.value > 0) {
      return {
        ...defaultCellStyle,
        background: greenColor,
      };
    } else {
      return defaultCellStyle;
    }
  };

  const DetailedSummaryColInfo = [
    { field: "broker", hide: false, cellStyle: defaultCellStyle },
    { field: "id", filter: true, cellStyle: defaultCellStyle },
    {
      field: "symbol",
      filter: false,
      cellStyle: { ...defaultCellStyle, justifyContent: "left" },
    },
    {
      field: "strike",
      sort: "asc",
      filter: false,
      cellStyle: defaultCellStyle,
    },
    { field: "type", filter: false, sort: "asc", cellStyle: defaultCellStyle },
    { field: "EVWAP", filter: false, cellStyle: defaultCellStyle },
    { field: "moneyness", cellStyle: redGreenCellColorLogic },
    { field: "bidPrice", filter: false, cellStyle: defaultCellStyle },
    { field: "askPrice", filter: false, cellStyle: defaultCellStyle },
    { field: "bidQuote", filter: false, cellStyle: defaultCellStyle },
    { field: "askQuote", filter: false, cellStyle: defaultCellStyle },
    { field: "netPos", filter: false, cellStyle: redGreenCellColorLogic },
    { field: "capturedpnl", cellStyle: redGreenCellColorLogic },
    { field: "currentpnl", cellStyle: redGreenCellColorLogic },
    { field: "netpnl", cellStyle: redGreenCellColorLogic },
    {
      field: "Updated at",
      filter: false,
      cellStyle: lastUpdatedTimeLagColorLogic,
    },
    { field: "spread", filter: false, cellStyle: defaultCellStyle },
    { field: "conf1", filter: false, cellStyle: defaultCellStyle },
    { field: "conf2", filter: false, cellStyle: defaultCellStyle },
    { field: "build", filter: false, cellStyle: defaultCellStyle },
    { field: "sqoff", filter: false, cellStyle: defaultCellStyle },
    { field: "buyAvgPrice", filter: false, cellStyle: defaultCellStyle },
    { field: "sellAvgPrice", filter: false, cellStyle: defaultCellStyle },
    { field: "buyLots", filter: false, cellStyle: defaultCellStyle },
    { field: "sellLots", filter: false, cellStyle: defaultCellStyle },
  ];
  const strategyFilters = DetailedStrategySummaryStore(
    (state) => state.strategyFilters
  );
  const gridApi = useRef(null);

  const fetchRowData = async () => {
    if (isFetching) return null;
    setIsFetching(true); // Set fetching state to true

    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getDashboardData?packetType=detailedStrategySummary"
    )
      .then((response) => response.json())
      .then((data) => {
        const newRowData = data.data.map((row) => {
          const newRow = {};
          DetailedSummaryColInfo.forEach((col, index) => {
            newRow[col["field"]] = row[index];
          });
          return newRow;
        });
        gridApi.current.api.setGridOption("rowData", newRowData);
        setIsFetching(false);
        // setRowData(newRowData);
      })
      .catch((error) =>
        console.error("Error fetching row definitions:", error)
      );
    // .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    // Call fetchData immediately
    fetchRowData();

    const intervalId = setInterval(() => {
      fetchRowData();
    }, 3000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs once on mount

  const onGridReady = async (params) => {
    gridApi.current = params;
  };
  const getPrimaryKey = (params) => {
    return String(
      params.data["broker"] + params.data["id"] + params.data["symbol"]
    );
  };

  const onFirstDataRendered = useCallback((params) => {
    gridApi.current = params;
    gridApi.current.api.setFilterModel(strategyFilters);
    const header =
      strategyFilters?.broker?.filter +
      " " +
      String(strategyFilters?.id?.filter);
    document.title = strategyFilters ? `AXD ${header}` : "AX Dashboard";
    gridApi.current.api.autoSizeAllColumns();
  }, []);

  const getRowStyle = (params) => {
    if (params.data["type"] === "PE") {
      return { background: "lightgrey" };
    } else if (params.data["type"] === "EQ") {
      return { background: "lightblue" };
    }
    // else if (params.data["type"] === "CE") {
    //   return { background: "" };
    // }
    return null;
  };

  return (
    <div
      className="ag-theme-balham"
      style={{
        fontSize: dashboardCellFontSize,
        height: "95vh",
        padding: 0,
        paddingLeft: 0,
        paddingRight: 0,
        fontWeight: "bold",
      }}
    >
      <style>
        {`
          .ag-header-cell {
            text-overflow: clip;
            overflow: visible;
            white-space: normal;
            padding: 0 !important; 
          };
          
          
        `}
      </style>
      {/* <AdjustableBox heightValue="90vh"> */}
      <AgGridReact
        getRowId={getPrimaryKey}
        onGridReady={onGridReady}
        columnDefs={DetailedSummaryColInfo}
        gridOptions={{
          // headerHeight:
          autoSizeStrategy: {
            type: "fitCellContents",
            skipHeader: false,
          },
        }}
        defaultColDef={{
          cellStyle: { fontSize: dashboardCellFontSize },
          filter: true,
        }}
        rowHeight={20}
        getRowStyle={getRowStyle}
        animateRows={false}
        onFirstDataRendered={onFirstDataRendered}
        suppressColumnVirtualisation={true}
        rowSelection="multiple" // Enable multiple row selection
      />
      {/* </AdjustableBox> */}
    </div>
  );
}
