// import React from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Chip,
// } from "@mui/material";

// const statusStyles = {
//   Healthy: { backgroundColor: "#28a745", color: "white" },
//   Warning: { backgroundColor: "#ffc107", color: "black" },
//   Critical: { backgroundColor: "#dc3545", color: "white" },
// };

// function HeartbeatDetails({ data }) {
//   console.log(data);
//   return (
//     <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>System</TableCell>
//             <TableCell>Process</TableCell>
//             <TableCell>Status</TableCell>
//             <TableCell>Last Heartbeat</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {data?.map((item, index) => (
//             <TableRow key={index}>
//               <TableCell>{item.system}</TableCell>
//               <TableCell>{item.process}</TableCell>
//               <TableCell>
//                 <Chip label={item.status} style={statusStyles[item.status]} />
//               </TableCell>
//               <TableCell>{item.lastHeartbeat}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

// export default HeartbeatDetails;

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  Collapse,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const statusStyles = {
  Healthy: { backgroundColor: "#28a745", color: "white" },
  Warning: { backgroundColor: "#ffc107", color: "black" },
  Critical: { backgroundColor: "#dc3545", color: "white" },
};

// Function to group data by process
const groupByProcess = (data) => {
  return data.reduce((result, item) => {
    if (!result[item.process]) {
      result[item.process] = [];
    }
    result[item.process].push(item);
    return result;
  }, {});
};

// Function to check if any of the process group items have a "Critical" status
const isProcessCritical = (items) => {
  return items.some((item) => item.status === "Critical");
};

function HeartbeatDetails({ data }) {
  const groupedData = groupByProcess(data); // Grouping data by process
  const [openProcesses, setOpenProcesses] = useState({}); // State to track collapsible sections

  const toggleCollapse = (process) => {
    setOpenProcesses((prevState) => ({
      ...prevState,
      [process]: !prevState[process], // Toggle open/closed state
    }));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Process</TableCell>
            <TableCell>System</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Last Heartbeat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedData).map((process, index) => {
            const processItems = groupedData[process];
            const processCritical = isProcessCritical(processItems); // Check if any item in the process group is critical

            return (
              <React.Fragment key={index}>
                {/* Display process row with a toggle button and color based on status */}
                <TableRow
                  style={{
                    fontWeight: "bold",
                    backgroundColor: processCritical ? "#dc3545" : "#28a745", // Red if critical, green otherwise
                    color: "white",
                  }}
                >
                  <TableCell colSpan={4}>
                    <IconButton
                      onClick={() => toggleCollapse(process)}
                      style={{ color: "white" }}
                    >
                      {openProcesses[process] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    {process}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={4}>
                    <Collapse
                      in={openProcesses[process]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Table size="small">
                        <TableBody>
                          {/* Display systems related to the process */}
                          {processItems.map((item, idx) => (
                            <TableRow key={idx}>
                              <TableCell>{item.process}</TableCell>
                              <TableCell>{item.system}</TableCell>
                              <TableCell>
                                <Chip
                                  label={item.status}
                                  style={statusStyles[item.status]}
                                />
                              </TableCell>
                              <TableCell>{item.lastHeartbeat}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default HeartbeatDetails;
