import React, { createContext, useEffect } from "react";
import NotificationStore from "../Store/NotificationStore";
// import notificationSound from "../assets/notification-sound.mp3";

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  let socket = null;

  useEffect(() => {
    // Request notification permission
    // if ("Notification" in window && Notification.permission !== "granted") {
    //   Notification.requestPermission();
    // }
    // eslint-disable-next-line
    socket = new WebSocket(process.env.REACT_APP_BASE_WS_API_URL + "/ws");

    socket.onopen = () => {
      console.log("WebSocket connected");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket closed. Reconnecting...");
      // Reconnect logic if needed
    };

    socket.onmessage = (event) => {
      NotificationStore.setState((state) => ({
        unreadCount: state.unreadCount + 1,
      }));
      console.log(event.data);
      // showNotification(event.data);
      // Play notification sound
      // const audio = new Audio(notificationSound);
      // audio.play();
    };

    return () => {
      socket.close();
    };
  }, []);

  const showNotification = (message) => {
    if ("Notification" in window && Notification.permission === "granted") {
      console.log("trying to show notification");
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification("AX Dashboard Error", {
          body: message,
        });
      });
      // // Play notification sound
      // const audio = new Audio(notificationSound);
      // audio.play();
    }
  };

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};
