import { React, useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  Box,
  Grid,
} from "@mui/material";
import HeartbeatDetails from "./HeartbeatDetails";
import Attachments from "./DownloadBSEFilesFromMail";
import RequestItem from "./RequestItem";
export default function OpsView() {
  const primaryTitle = "primaryTitle";
  const secondaryTitle = "secondaryTitle";
  const buttonText = "Download to internal backend";
  const processRequest = () => {
    // Define the function to handle the button click
    console.log("Processing request...");
  };
  const [heartbeatData, setHeartbeatData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_API_URL + "/getStatusData"
        );
        const data = await response.json();
        setHeartbeatData(data);
      } catch (error) {
        console.error("Error fetching the data", error);
      }
    };

    fetchData(); // Initial fetch
    const intervalId = setInterval(fetchData, 3000); // Fetch every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);
  return (
    <Container>
      <Typography
        variant="h3"
        sx={{
          fontSize: { xs: "2rem", md: "3rem" },
          textAlign: "center",
          marginBottom: "1rem",
        }}
      >
        Status
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="heartbeat-content"
          id="heartbeat-header"
        >
          <Typography>Heartbeats</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HeartbeatDetails data={heartbeatData} />
        </AccordionDetails>
      </Accordion>
      <Typography
        variant="h3"
        sx={{
          fontSize: { xs: "2rem", md: "3rem" },
          textAlign: "center",
          marginBottom: "1rem",
        }}
      >
        Manage Processes
      </Typography>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="bod-content"
          id="bod-header"
        >
          <Typography>Beginning of Day Processes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Attachments />
          <RequestItem
            primaryTitle="Download BSE Bhav Copy"
            secondaryTitle="From BSE website"
            buttonText="Download to internal backend"
            requestPath="/downloadBSEFiles"
          />
          <RequestItem
            primaryTitle="Download NSE IDX Close Prices"
            secondaryTitle="From NSE website"
            buttonText="Download to internal backend"
            requestPath="/downloadIDXDailyClosePrice"
          />
          <RequestItem
            primaryTitle="TBT Generation & BOD"
            secondaryTitle="generate tbt subscription files & upload data to SQL"
            buttonText="Generate @ internal backend"
            requestPath="/executebod"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="eod-content"
          id="eod-header"
        >
          <Typography>End of Day Processes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RequestItem
            primaryTitle="Generate EOD Files & Upload to SQL"
            secondaryTitle="Tradebook, Strategy Mapping Table & Netpositions"
            buttonText="Execute"
            requestPath="/executeeod"
          />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
