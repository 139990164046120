import React from "react";
import PlotContainer from "./PlotContainer";
import "./Plotter.css";

const Plotter = () => {
  return (
    <div className="parent-container">
      <PlotContainer quadrant={1} />
      <PlotContainer quadrant={2} />
      <PlotContainer quadrant={3} />
      <PlotContainer quadrant={4} />
    </div>
  );
};

export default Plotter;
