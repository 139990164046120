import { create } from "zustand";

export const usePlotterFilterStore = create((set) => ({
  // Initialize filters from localStorage
  filters: JSON.parse(localStorage.getItem("plotterFilters")) || {},
  visibility: JSON.parse(localStorage.getItem("plotterVisibility")) || {},

  // Set and persist filters
  setFilter: (quadrant, filterName, value) =>
    set((state) => {
      const updatedFilters = {
        ...state.filters,
        [quadrant]: {
          ...state.filters[quadrant],
          [filterName]: value,
        },
      };
      // localStorage.setItem("plotterFilters", JSON.stringify(updatedFilters));
      return { filters: updatedFilters };
    }),

  // Set and persist visibility state
  setVisibility: (quadrant, traceName, isVisible) =>
    set((state) => {
      const updatedVisibility = {
        ...state.visibility,
        [quadrant]: {
          ...state.visibility[quadrant],
          [traceName]: isVisible,
        },
      };
      localStorage.setItem(
        "plotterVisibility",
        JSON.stringify(updatedVisibility)
      );
      return { visibility: updatedVisibility };
    }),
}));
