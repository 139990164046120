import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { usePlotterFilterStore } from "../../Store/PlotterFilterStore";

const Tracer = ({ data, quadrant, dataPoints }) => {
  const [plotData, setPlotData] = useState([]);
  const [layout, setLayout] = useState({});
  const [revision, setRevision] = useState(0);

  // Get visibility state from Zustand store
  const visibility = usePlotterFilterStore(
    (state) => state.visibility[quadrant] || {}
  );
  const setVisibility = usePlotterFilterStore((state) => state.setVisibility);

  useEffect(() => {
    if (data && data.xAxis && data.yAxis) {
      const xAxisKey = Object.entries(data.xAxis)[0][0];
      const xValues = data.xAxis[xAxisKey];
      const yAxisData = Object.entries(data.yAxis);

      // Determine how many data points to display
      const pointsToShow = dataPoints
        ? Math.min(xValues.length, dataPoints)
        : xValues.length;

      // Slice the data arrays to only show the last X data points
      const slicedXValues = xValues.slice(-pointsToShow);
      const formattedData = yAxisData.map(([yAxisName, yValues]) => {
        const slicedYValues = yValues.slice(-pointsToShow);
        return {
          x: slicedXValues,
          y: slicedYValues,
          type: "scatter",
          mode: "lines",
          name: yAxisName,
          visible:
            visibility[yAxisName] !== undefined ? visibility[yAxisName] : true, // Apply stored visibility state
        };
      });

      setPlotData(formattedData);
      setRevision((rev) => rev + 1); // Trigger re-render

      const annotations = formattedData
        .filter(({ visible }) => visible === true) // Only include visible traces
        .map(({ name, y }) => {
          const latestValue = y[y.length - 1];
          return {
            xref: "x", // Reference the x-axis directly
            yref: "y",
            x: slicedXValues[slicedXValues.length - 1], // Last x value
            y: latestValue,
            xanchor: "left",
            yanchor: "middle",
            text: `${latestValue}`,
            showarrow: false,
            ax: 20, // Offset to the left to prevent overflow
            ay: 0,
            font: { size: 12 },
          };
        });

      setLayout((prevLayout) => ({
        ...prevLayout,
        xaxis: {
          nticks: 10, // Manage the number of visible ticks on the x-axis
          tickmode: "auto", // Auto-tick management
        },
        yaxis: {
          autorange: true,
        },
        showlegend: true, // Always show the legend
        legend: {
          orientation: "h", // Display the legend horizontally
          x: 0,
          y: 1.1, // Position the legend at the top
          xanchor: "left",
          yanchor: "bottom",
        },
        annotations: annotations, // Update annotations based on visibility
      }));
    }
  }, [data, dataPoints, visibility]);

  const handleUpdate = (figure) => {
    setLayout(figure.layout); // Capture updated layout

    // Update visibility state in Zustand store
    figure.data.forEach((trace, index) => {
      setVisibility(quadrant, trace.name, trace.visible);
    });
  };

  return (
    <div className="tracer">
      <Plot
        data={plotData}
        layout={layout}
        revision={revision} // Use revision to control re-rendering
        onInitialized={(figure) => setLayout(figure.layout)}
        onUpdate={handleUpdate}
        useResizeHandler
        style={{ width: "100%", height: "100%", margin: 0, padding: 0 }} // Ensure Plot fills the container
      />
    </div>
  );
};

export default Tracer;
