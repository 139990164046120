import { create } from "zustand";

const useStrategyParamsStore = create((set) => ({
  anchorEl: null,
  open: false,
  filter: null,

  setAnchorEl: (anchorEl) => set({ anchorEl }),
  setOpen: (open) => set({ open }),
  setFilter: (filter) => set({ filter }),
  resetParams: () => set({ anchorEl: null, open: false, filter: null }),
}));

export default useStrategyParamsStore;
