import { React, useState, useEffect, useMemo, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Popper,
  Box,
  Typography,
  Container,
  Paper,
  Button,
} from "@mui/material";
import useStrategyParamsStore from "../../../Store/StrategyParamsStore";

function StrategyParams() {
  const {
    anchorEl,
    open,
    filter,
    setAnchorEl,
    setOpen,
    setFilter,
    resetParams,
  } = useStrategyParamsStore();

  const memoizedFilter = useMemo(() => filter, [filter]);
  const memoizedOpen = useMemo(() => open, [open]);

  const [showBaseParams, setShowBaseParams] = useState(true);
  const popperRef = useRef(null);

  const handleToggle = () => {
    setShowBaseParams((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, setOpen]);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const memoizedData = useMemo(() => data, [data]);

  useEffect(() => {
    let intervalId = null;
    const fetchData = () => {
      //   setLoading(true);
      setError(null); // Reset error on each fetch
      fetch(
        `https://backend.alphextra.com/getStrategyParams?broker=${filter.broker}&id=${filter?.id}`
      ) // Replace with your API endpoint
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    };
    if (open) {
      setLoading(true);
      setData(null); // Reset data on each open

      fetchData();
      intervalId = setInterval(fetchData, 3000); // Fetch every second
    }

    return () => {
      if (intervalId) clearInterval(intervalId); // Clear the interval when closed
    };
  }, [memoizedOpen, memoizedFilter]);

  const renderParams = (title, params) => (
    <Box sx={{ flex: 1 }}>
      <h3 style={{ color: "lightblue" }}>{title}</h3>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {Object.entries(params).map(([key, value], index) => (
          <Typography
            key={index}
            fontSize={10}
            padding={0}
            sx={{
              flex: "0 0 auto",
              whiteSpace: "nowrap",
              margin: "4px",
              backgroundColor: "#333",
              color: "white",
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            <strong>{key}:</strong> {value}
          </Typography>
        ))}
      </Box>
    </Box>
  );
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      disablePortal
      ref={popperRef}
      placement="bottom-start"
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 100], // 80px below the anchor element
          },
        },
      ]}
    >
      <Container
        sx={{
          backgroundColor: "black",
          color: "white",
          borderRadius: "8px",
          maxWidth: "200px", // Adjust maxWidth as needed
          maxHeight: "50%", // Adjust maxHeight as needed for scrollable area
          overflow: "auto", // Enable scrolling when content exceeds maxHeight
          zIndex: 1000,
          padding: "16px",
        }}
      >
        <Typography color={"primary"}>
          {filter?.broker} {filter?.id}{" "}
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleToggle}
            sx={{ marginLeft: "16px" }}
          >
            {showBaseParams ? "Show Strategy Params" : "Show Base Params"}
          </Button>
        </Typography>

        {loading && <Typography>Loading...</Typography>}
        {error && <Typography sx={{ color: "red" }}>Error: {error}</Typography>}
        {data && (
          <Box sx={{ flexGrow: 1 }}>
            {showBaseParams
              ? renderParams("BASE Params", data["strategyParams"]["BASE"])
              : renderParams(
                  "STRATEGY Params",
                  data["strategyParams"]["STRATEGY"]
                )}
          </Box>
        )}
      </Container>
    </Popper>
  );
}

export default StrategyParams;
