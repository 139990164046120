/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional Theme applied to the grid
// import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import timeFilter from "../../AGCustomFilters/timeFilter";
// import NewTimeFilter from "../../AGCustomFilters/NewTimeFilter";
import moneyFormatter from "../../AGCustomFilters/moneyFormatter";
import DetailedStrategySummaryStore from "../../Store/DetailedStrategySummaryStore";
import StrategySummaryStore from "../../Store/StrategySummary";
import StrategyParams from "./StrategyParams/StrategyParams";
import useStrategyParamsStore from "../../Store/StrategyParamsStore";
import CustomContextMenu from "../ContextMenu/CustomContextMenu";
import useDevModeStore from "../../Store/DevModeStore"; // Import the Zustand store

const TopRightAnchor = () => {
  return (
    <div
      className="top-right-anchor"
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        width: "1px",
        height: "1px",
      }}
    />
  );
};

export default function StrategySummary({ isDetailedView, isMobile }) {
  const { anchorEl, open, filter, setAnchorEl, setOpen, setFilter } =
    useStrategyParamsStore();
  const { isDevMode, setIsDevMode } = useDevModeStore(); // Use Zustand store

  const [contextMenuOptions, setContextMenuOptions] = useState({});

  const strategyFilters = DetailedStrategySummaryStore(
    (state) => state.strategyFilters
  );
  const updateStrategyFilters = DetailedStrategySummaryStore(
    (state) => state.updateStrategyFilters
  );
  const setVisibleRows = StrategySummaryStore((state) => state.setVisibleRows);

  const dashboardCellFontSize = isMobile ? 5 : 9;
  const redColor = "indianred";
  const greenColor = "lightgreen";
  const orangeColor = "orange";
  const defaultCellStyle = {
    padding: "0px",
    fontSize: dashboardCellFontSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  };
  const isTimeWithin = (timeString, duration) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const now = new Date();
    // const offsetIST = 5.5 * 60; // IST is UTC+5:30
    const nowInIST = new Date(now.getTime());

    const providedTimeInIST = new Date(nowInIST);
    providedTimeInIST.setHours(hours);
    providedTimeInIST.setMinutes(minutes);
    providedTimeInIST.setSeconds(seconds);
    const differenceInMillis = Math.abs(nowInIST - providedTimeInIST);
    const differenceInSeconds = differenceInMillis / 1000;
    const isWithinXSeconds = differenceInSeconds <= duration;
    return isWithinXSeconds;
  };
  const lastUpdatedTimeLagColorLogic = (params) => {
    if (!isTimeWithin(params.value, 30)) {
      return {
        ...defaultCellStyle,
        background: redColor,
      };
    } else return defaultCellStyle;
  };
  const redGreenCellColorLogic = (params) => {
    if (params.value < 0) {
      return {
        ...defaultCellStyle,
        background: redColor,
      };
    } else if (params.value > 0) {
      return {
        ...defaultCellStyle,
        background: greenColor,
      };
    } else {
      return defaultCellStyle;
    }
  };
  const strategyStateColorLogic = (params) => {
    if (params.value === "LIVE") {
      return {
        ...defaultCellStyle,
        background: greenColor,
      };
    } else if (params.value === "LOGG") {
      return {
        ...defaultCellStyle,
        background: redColor,
      };
    } else if (params.value === "REBL") {
      return {
        ...defaultCellStyle,
        background: orangeColor,
      };
    } else return defaultCellStyle;
  };

  const strategySummaryColInfo = [
    {
      field: "broker",
      cellStyle: defaultCellStyle,
    },
    { field: "id", cellStyle: defaultCellStyle },
    { field: "strategyName", cellStyle: defaultCellStyle },
    { field: "symbolName", cellStyle: defaultCellStyle },
    { field: "evwap", cellStyle: defaultCellStyle },
    { field: "chng", cellStyle: redGreenCellColorLogic, filter: false },
    { field: "buildPuts", cellStyle: defaultCellStyle },
    { field: "buildCalls", cellStyle: defaultCellStyle },
    { field: "sqOff", cellStyle: defaultCellStyle },
    { field: "PE Longs", cellStyle: defaultCellStyle },
    { field: "PE Shorts", cellStyle: defaultCellStyle },
    { field: "CE Longs", cellStyle: defaultCellStyle },
    { field: "CE Shorts", cellStyle: defaultCellStyle },
    { field: "PE Alowd", cellStyle: defaultCellStyle },
    { field: "CE Alowd", cellStyle: defaultCellStyle },
    {
      field: "Cap P&L",
      cellStyle: redGreenCellColorLogic,
      valueFormatter: (params) => moneyFormatter(params.data["Cap P&L"]),
    },
    {
      field: "Curr P&L",
      cellStyle: redGreenCellColorLogic,
      valueFormatter: (params) => moneyFormatter(params.data["Curr P&L"]),
    },
    {
      field: "Net P&L",
      cellStyle: redGreenCellColorLogic,
      valueFormatter: (params) => moneyFormatter(params.data["Net P&L"]),
    },
    {
      field: "var",
      cellStyle: defaultCellStyle,
      valueFormatter: (params) =>
        typeof params.data["var"] === "number"
          ? moneyFormatter(params.data["var"] / 100000)
          : "N.A.",
    },
    // {
    //   field: "varDown",
    //   cellStyle: defaultCellStyle,
    //   valueFormatter: (params) =>
    //     typeof params.data["varDown"] === "number"
    //       ? moneyFormatter(params.data["varDown"] / 100000)
    //       : "N.A.",
    // },
    // {
    //   field: "varUp",
    //   cellStyle: defaultCellStyle,
    //   valueFormatter: (params) =>
    //     typeof params.data["varUp"] === "number"
    //       ? moneyFormatter(params.data["varUp"] / 100000)
    //       : "N.A.",
    // },
    { field: "State", cellStyle: strategyStateColorLogic },
    {
      field: "Updated Time",
      filter: timeFilter,
      cellStyle: lastUpdatedTimeLagColorLogic,
    },
    {
      field: "strategyComment",
      cellStyle: { ...defaultCellStyle, justifyContent: "left" },
    },
  ];
  const gridApi = useRef(null);
  const navigate = useNavigate();

  const fetchRowData = async () => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getDashboardData?packetType=strategySummary"
    )
      .then((response) => response.json())
      .then((data) => {
        let newRowData = data.data.map((row) => {
          const newRow = {};
          strategySummaryColInfo.forEach((col, index) => {
            newRow[col["field"]] = row[index];
            // console.log(col["field"], row[index]);
          });
          return newRow;
        });

        // Filter data based on Dev mode during data fetch
        if (!isDevMode) {
          newRowData = newRowData.filter((row) => {
            console.log(row);
            return !("UAT UAT" === row["broker"]);
          });
        }

        gridApi.current.api.setGridOption("rowData", newRowData);
        captureVisibleRows();
        // setRowData(newRowData);
      })
      .catch((error) =>
        console.error("Error fetching row definitions:", error)
      );
  };

  const captureVisibleRows = () => {
    if (gridApi.current) {
      let allVisibleRows = gridApi.current.api
        .getRenderedNodes()
        .map((node) => node.data);
      setVisibleRows(allVisibleRows);
    }
  };

  useEffect(() => {
    captureVisibleRows();
  }, [isDevMode]);

  useEffect(() => {
    // Call fetchData immediately
    fetchRowData();
    const intervalId = setInterval(fetchRowData, 2000);

    // Cleanup function to clear the interval when the component is unmounted
    // eslint-disable-next-line
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs once on mount

  const onGridReady = (params) => {
    gridApi.current = params;
  };
  const getPrimaryKey = (params) => {
    return String(params.data["broker"] + params.data["id"]);
  };

  const onFirstDataRendered = useCallback((params) => {
    gridApi.current = params;
    if (isDetailedView) {
      gridApi.current.api.setFilterModel(strategyFilters);
    }
    gridApi.current.api.autoSizeAllColumns();
    captureVisibleRows();
  }, []);

  const onRowDoubleClicked = useCallback(async (event) => {
    console.log("row double clicked");
    // alert(`Row data: ${JSON.stringify(event.data)}`);
    console.log(event.data["broker"], event.data["id"]);
    await updateStrategyFilters({
      broker: {
        type: "equals",
        filter: event.data["broker"],
      },
      id: {
        type: "equals",
        filter: event.data["id"],
      },
    });

    navigate("/detailed-strategy-summary");

    // You can replace the alert with more complex logic
    // to show the details of the row in a modal or another component
  }, []);

  const deleteRow = async (dataToDelete) => {
    console.log("data to be deleted");
    console.log(dataToDelete);
    // Remove the row from the grid
    // gridApi.current.api.applyTransaction({ remove: [dataToDelete] });

    try {
      // Send POST request to the server using Fetch API
      const response = await fetch(
        process.env.REACT_APP_BASE_API_URL + "/removeRow",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            packetType: "strategySummary",
            data: dataToDelete,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("Row deleted successfully", responseData);
      } else {
        console.error("Error deleting row", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting row", error);
    }
  };

  const handleStrategyParamsView = () => {
    const selectedRow = gridApi.current.api.getSelectedRows()[0];
    setOpen(true);
    setAnchorEl(document.querySelector(".top-right-anchor"));
    setFilter({ broker: selectedRow.broker, id: selectedRow.id } || "No data");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      const selectedNodes = gridApi.current.api.getSelectedRows();
      if (selectedNodes.length > 0) {
        deleteRow(selectedNodes[0]);
      }
    } else if (event.ctrlKey && event.key === "s") {
      handleStrategyParamsView();
    } else if (event.ctrlKey && event.key === "h") {
      setOpen(false);
    } else {
      console.log(event.key);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const getRowStyle = (params) => {
    if (!isTimeWithin(params.data["Updated Time"], 60)) {
      return { background: "pink" };
    }
    return null;
  };

  const setToolTipFalse = () => {
    console.log("hiding params");
    setOpen(false);
  };

  useEffect(() => {
    setContextMenuOptions({
      [`${open ? "Hide" : "Show"} Strategy Params`]: open
        ? setToolTipFalse
        : handleStrategyParamsView,
    });
  }, [open]);

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-balham"
      style={{
        fontSize: dashboardCellFontSize,
        height: isDetailedView ? "7.5vh" : "65vh",
        resize: "vertical",
        overflow: "auto",
      }}
    >
      <style>
        {`
          .ag-header-cell {
            text-overflow: clip;
            overflow: visible;
            white-space: normal;
            padding: 0 !important;
          }
        `}
      </style>

      <AgGridReact
        getRowId={getPrimaryKey}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={captureVisibleRows}
        multiSortKey="ctrl"
        columnDefs={strategySummaryColInfo}
        defaultColDef={{
          cellStyle: { fontSize: dashboardCellFontSize, padding: 0 },
          filter: true,
          flex: 1,
        }}
        gridOptions={{
          // rowHeight: 22,
          autoSizeStrategy: {
            type: "fitCellContents",
            skipHeader: true,
          },
        }}
        animateRows={false}
        onRowDoubleClicked={onRowDoubleClicked}
        // onRowClicked={onRowClicked}
        suppressColumnVirtualisation={true}
        rowSelection="single"
        getRowStyle={getRowStyle}
      />
      <TopRightAnchor />
      <StrategyParams />
      <CustomContextMenu functions={contextMenuOptions} />
    </div>
  );
}

function parseTime(timeString) {
  if (!timeString) return null;
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + (seconds || 0);
}
