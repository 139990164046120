import { create } from "zustand";
import OptionChainIcon from "@mui/icons-material/TableChart";
// import PortfolioIcon from "@mui/icons-material/PieChart";
import TransactionsIcon from "@mui/icons-material/TableRows";
import TradeAnalyticsIcon from "@mui/icons-material/Analytics";
import NotificationsIcon from "@mui/icons-material/Notifications";

const pagesStore = create((set) => ({
  pages: [
    // {
    //   title: "Reports",
    //   sidebarIcon: <PortfolioIcon />,
    //   path: "/reports",
    // },
    {
      title: "Detailed Strategy",
      sidebarIcon: <TransactionsIcon />,
      path: "/detailed-strategy-summary",
    },
    {
      title: "Strategy Summary",
      sidebarIcon: <OptionChainIcon />,
      path: "/trading-summary",
    },
    {
      title: "Errors",
      sidebarIcon: <NotificationsIcon />,
      path: "/notifications",
    },
    {
      title: "Plotter",
      sidebarIcon: <NotificationsIcon />,
      path: "/plotter",
    },
    // {
    //   title: "Sign Out",
    //   sidebarIcon: <TradeAnalyticsIcon />,
    //   path: "/sign-out",
    // },
    {
      title: "OPS",
      sidebarIcon: <TradeAnalyticsIcon />,
      path: "/ops",
    },
  ],
  updatePages: () => set((state) => ({ pages: state.pages })),
}));

export default pagesStore;
