import React, { useState, useEffect } from "react";
import { Menu, MenuItem } from "@mui/material";

export default function CustomContextMenu({ functions }) {
  const [mousePosition, setMousePosition] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
      setMousePosition({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
      setMenuOpen(true);
    };

    const handleClick = () => {
      setMenuOpen(false);
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <Menu
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
      anchorReference="anchorPosition"
      anchorPosition={
        mousePosition !== null
          ? { top: mousePosition.mouseY, left: mousePosition.mouseX }
          : undefined
      }
      PaperProps={{
        style: {
          width: 200,
        },
      }}
    >
      {Object.entries(functions).map(([label, action], index) => (
        <MenuItem
          key={index}
          onClick={() => {
            action();
            setMenuOpen(false);
          }}
        >
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
}
