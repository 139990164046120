// Navbar.js
import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, Drawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import pagesStore from "../Store/PagesStore";
import SideBar from "./SideBar";

function Navbar() {
  // eslint-disable-next-line
  const { pages, upadatePages } = pagesStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(false); // Close sidebar when switching from mobile to desktop view
  }, [isMobile]);

  const toggleDrawer = (open) => (event) => {
    setOpen(open);
  };

  return (
    <div>
      <AppBar
        sx={{
          position: "static",
          height: "35px",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontWeight: "bold" }}
          >
            AX Dashboard
          </Typography>
          {isMobile ? (
            <Button color="inherit" onClick={toggleDrawer(true)}>
              Menu
            </Button>
          ) : (
            <div>
              {pages.map((item, index) => (
                <Button
                  key={index}
                  color="inherit"
                  component={Link}
                  to={item.path}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
                    {item.title}
                  </Typography>
                </Button>
              ))}
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <div onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <SideBar />
        </div>
      </Drawer>
      <Outlet />
    </div>
  );
}

export default Navbar;
