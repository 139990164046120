export default function moneyFormatter(currency, sign = undefined) {
  if (!(typeof currency === "number")) {
    return "N.A.";
  }
  var sansDec = currency?.toFixed(0);
  //   var formatted = sansDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //international number system
  var formatted = sansDec?.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ","); //indian number system
  return sign ? `${sign} ${formatted}` : formatted;
}

export function varFormatter(currency, sign = undefined) {
  if (!(typeof currency === "number")) {
    return "N.A.";
  }
  var sansDec = currency?.toFixed(2);
  //   var formatted = sansDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //international number system
  var formatted = sansDec?.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ","); //indian number system
  return sign ? `${sign} ${formatted}` : formatted;
}
