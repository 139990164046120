import DetailedStrategyView from "../DetailedStrategyView";
import StrategySummary from "../StrategySummary";
import AccountSummary from "../AccountSummary";
export default function DetailedStrategySummary({ isMobile }) {
  return (
    <div>
      <AccountSummary isDetailedView={true} isMobile={isMobile} />
      <StrategySummary isDetailedView={true} isMobile={isMobile} />
      <DetailedStrategyView isMobile={isMobile} />
    </div>
  );
}
