import { React, useState } from "react";
import StrategySummary from "../StrategySummary";
import AccountSummary from "../AccountSummary";
import TotalPnLGrid from "../TotalPnLGrid";
import NotificationStore from "../../../Store/NotificationStore";
// import TickerTape from "./TickerTape";
import TickerTape from "./GetPrice";

export default function DashboardSummary({ isMobile }) {
  const unreadCount = NotificationStore((state) => state.unreadCount);
  document.title = unreadCount
    ? `(${unreadCount}) AX Dashboard`
    : "AX Dashboard";

  return (
    <div style={{ padding: "5px" }}>
      <TotalPnLGrid />
      {/* <TickerTape /> */}
      <StrategySummary isDetailedView={false} isMobile={isMobile} />
      <AccountSummary isDetailedView={false} isMobile={isMobile} />
    </div>
  );
}
