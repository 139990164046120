import { React, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the grid
// import { Typography, List, ListItem, ListItemText } from "@mui/material";
import NotificationStore from "../Store/NotificationStore";
import { Button } from "@mui/material";

const Notifications = () => {
  // const [notifications, setNotifications] = useState([]);
  const resetUnreadCount = NotificationStore((state) => state.resetUnreadCount);
  useEffect(() => {
    // Reset unread count when the notifications page is opened
    resetUnreadCount();
  }, [resetUnreadCount]);

  const dashboardCellFontSize = "9px";
  const redColor = "indianred";
  // const greenColor = "lightgreen";
  const defaultCellStyle = {
    padding: "0px",
    fontSize: dashboardCellFontSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const notificationsColInfo = [
    { field: "broker", cellStyle: defaultCellStyle },
    { field: "priority", cellStyle: defaultCellStyle },
    { field: "message", cellStyle: defaultCellStyle },
    { field: "time", cellStyle: defaultCellStyle, sort: "desc" },
  ];

  const gridApi = useRef(null);

  const sendNotification = () => {
    console.log("sending notification");
    const message = { message: "This is a test notification" };
    fetch(process.env.REACT_APP_BASE_API_URL + "/send-test-notification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) =>
        console.error("Error fetching row definitions:", error)
      );
  };
  const fetchRowData = async () => {
    fetch(process.env.REACT_APP_BASE_API_URL + "/fetch-notifications")
      .then((response) => response.json())
      .then((data) => {
        // console.log(Object.values(data.data));
        const newRowData = Object.values(data.data).map((row) => {
          const newRow = {};
          notificationsColInfo.forEach((col, index) => {
            newRow[col["field"]] = row[index];
          });
          return newRow;
        });
        gridApi.current.api.setGridOption("rowData", newRowData);
      })
      .catch((error) =>
        console.error("Error fetching row definitions:", error)
      );
  };
  useEffect(() => {
    // Call fetchData immediately
    fetchRowData();

    // const intervalId = setInterval(fetchRowData, 2000);

    // // Cleanup function to clear the interval when the component is unmounted
    // return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this runs once on mount

  const getRowStyle = (params) => {
    if (params.data["priority"] === "HIGH") {
      return { background: redColor };
    }
    return null;
  };

  const onGridReady = (params) => {
    gridApi.current = params;
  };
  const getPrimaryKey = (params) => {
    return (
      String(params.data["broker"]) +
      String(params.data["message"]) +
      String(params.data["time"])
    );
  };

  const onFirstDataRendered = useCallback((params) => {
    gridApi.current = params;
    gridApi.current.api.autoSizeAllColumns();
  }, []);

  return (
    <div>
      <Button onClick={sendNotification}>Send test Notification</Button>

      <div
        className="ag-theme-alpine"
        style={{
          fontSize: dashboardCellFontSize,
          height: "98vh",
          resize: "vertical",
          overflow: "auto",
          // paddingTop: "5px",
          // fontWeight: "bold",
        }}
      >
        <AgGridReact
          getRowId={getPrimaryKey}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          columnDefs={notificationsColInfo}
          defaultColDef={{
            cellStyle: { fontSize: dashboardCellFontSize },
            filter: true,
          }}
          gridOptions={{
            autoSizeStrategy: {
              type: "fitCellContents",
              skipHeader: false,
            },
          }}
          defaultSortModel={[{ colId: "time", sort: "desc" }]}
          suppressColumnVirtualisation={true}
          getRowStyle={getRowStyle}
        />
      </div>
    </div>
  );
};

export default Notifications;
