import React, { useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional Theme applied to the grid
import moneyFormatter from "../../AGCustomFilters/moneyFormatter";
import DetailedStrategySummaryStore from "../../Store/DetailedStrategySummaryStore";

export default function AccountSummary({ isDetailedView, isMobile }) {
  const strategyFilters = DetailedStrategySummaryStore(
    (state) => state.strategyFilters
  );
  const dashboardCellFontSize = isMobile ? 5 : 9;
  const redColor = "indianred";
  const greenColor = "lightgreen";
  const defaultCellStyle = {
    padding: "0px",
    fontSize: dashboardCellFontSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const isTimeWithin = (timeString, duration) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const now = new Date();
    const nowInIST = new Date(now.getTime());

    const providedTimeInIST = new Date(nowInIST);
    providedTimeInIST.setHours(hours);
    providedTimeInIST.setMinutes(minutes);
    providedTimeInIST.setSeconds(seconds);
    const differenceInMillis = Math.abs(nowInIST - providedTimeInIST);
    const differenceInSeconds = differenceInMillis / 1000;
    const isWithinXSeconds = differenceInSeconds <= duration;
    return isWithinXSeconds;
  };
  const lastUpdatedTimeLagColorLogic = (params) => {
    if (!isTimeWithin(params.value, 30)) {
      return {
        ...defaultCellStyle,
        background: redColor,
      };
    } else return defaultCellStyle;
  };
  const redGreenCellColorLogic = (params) => {
    if (params.value < 0) {
      return {
        ...defaultCellStyle,
        background: redColor,
      };
    } else if (params.value > 0) {
      return {
        ...defaultCellStyle,
        background: greenColor,
      };
    } else {
      return defaultCellStyle;
    }
  };
  const marginSummaryColInfo = [
    { field: "broker", cellStyle: defaultCellStyle },
    { field: "TotalDeposit (in Lakhs)", cellStyle: defaultCellStyle },
    { field: "MarginUtilized (in Lakhs)", cellStyle: defaultCellStyle },
    { field: "MarginUtlized%", cellStyle: defaultCellStyle },
    {
      field: "P&L",
      cellStyle: redGreenCellColorLogic,
      valueFormatter: (params) =>
        params.data["P&L"] ? moneyFormatter(params.data["P&L"]) : "N.A.",
    },
    { field: "LastUpdatedTime", cellStyle: lastUpdatedTimeLagColorLogic },
  ];
  const gridApi = useRef(null);
  const fetchRowData = async () => {
    fetch(
      process.env.REACT_APP_BASE_API_URL +
        "/getDashboardData?packetType=marginSummary"
    )
      .then((response) => response.json())
      .then((data) => {
        const newRowData = data.data.map((row) => {
          const newRow = {};
          marginSummaryColInfo.forEach((col, index) => {
            newRow[col["field"]] = row[index];
          });
          return newRow;
        });
        gridApi.current.api.setGridOption("rowData", newRowData);
        // setRowData(newRowData);
      })
      .catch((error) =>
        console.error("Error fetching row definitions:", error)
      );
  };
  useEffect(() => {
    // Call fetchData immediately
    fetchRowData();

    const intervalId = setInterval(fetchRowData, 2000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this runs once on mount

  const onGridReady = (params) => {
    gridApi.current = params;
  };
  const getPrimaryKey = (params) => {
    return String(params.data["broker"]);
  };

  const onFirstDataRendered = useCallback((params) => {
    gridApi.current = params;
    if (isDetailedView) {
      gridApi.current.api.setFilterModel(strategyFilters);
    }
    gridApi.current.api.autoSizeAllColumns();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="ag-theme-balham"
      style={{
        fontSize: dashboardCellFontSize,
        height: isDetailedView ? "7.5vh" : "20vh",
        resize: "vertical",
        overflow: "auto",
        paddingTop: "5px",
        fontWeight: "bold",
      }}
    >
      {/* <style>
          {`
          .ag-header-cell {
            padding: 0 !important;
          }
        `}
        </style> */}
      <AgGridReact
        getRowId={getPrimaryKey}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        columnDefs={marginSummaryColInfo}
        defaultColDef={{
          cellStyle: { fontSize: dashboardCellFontSize },
          filter: true,
        }}
        gridOptions={{
          autoSizeStrategy: {
            type: "fitCellContents",
            skipHeader: false,
          },
        }}
        detailRowAutoHeight={true}
        suppressColumnVirtualisation={true}
      />
    </div>
  );
}
