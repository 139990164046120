import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, List } from "@mui/material";
// import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import pagesStore from "../Store/PagesStore";

export default function SideBar() {
  // eslint-disable-next-line
  const { pages, updatePages } = pagesStore();

  return (
    <Box>
      <List>
        {pages.map((page, index) => (
          <ListItem key={index}>
            <ListItemButton component={Link} to={page.path}>
              <ListItemIcon>{page.sidebarIcon}</ListItemIcon>
              <ListItemText>
                <Typography>{page.title}</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
